import './App.css';
import theme from './theme';
import { useState, useEffect } from 'react';
import { fetchListItems, fetchFilesFromSharePoint, getCurrentUserInfo, fetchFolderTypeMapping } from './services/sharepoint';
import { SearchPanel } from './SearchPanel';
import SearchResult from './SearchResult';
import UploadCentre from './UploadCentre';
import Lists from './Lists.js';
import { loginRequest } from "./utils/authConfig";
import { Drawer, List, ListItemButton, ListItemText, ListItemIcon, Container, Typography, Grid, ThemeProvider, CssBaseline, CircularProgress, Backdrop, Button, Box, Dialog, DialogTitle, DialogContent, DialogActions, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import UploadIcon from '@mui/icons-material/CloudUpload';
import ListIcon from '@mui/icons-material/List';
import { useLocation, BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

// MSAL imports
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";

const drawerWidth = 200;

function LoginPage() {
  const { instance } = useMsal();
  const handleLoginClick = async () => {
    instance
    .handleRedirectPromise()
    .then((tokenResponse) => {
      if (!tokenResponse) {
        if (!instance.getActiveAccount()) {
          instance.loginRedirect(loginRequest);
        }
      }
    });
  }
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: '#000000' }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h5" gutterBottom noWrap component="div">
                (Electronic) Personnel Records Filing System
              </Typography>
              <Typography variant="h6" noWrap component="div">
                <img src="/PSALogo.png" alt="PSA Logo" className="psa-logo" />
              </Typography>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: 'auto' }}>
            <List>
              <ListItemButton>
                <ListItemIcon>
                  <SearchIcon />
                </ListItemIcon>
                <ListItemText primary="Search Centre" />
              </ListItemButton>
              <ListItemButton>
                <ListItemIcon>
                  <UploadIcon />
                </ListItemIcon>
                <ListItemText primary="Upload Centre" />
              </ListItemButton>
              <ListItemButton>
                <ListItemIcon>
                  <UploadIcon />
                </ListItemIcon>
                <ListItemText primary="Lists" />
              </ListItemButton>
            </List>
          </Box>
        </Drawer>
        <Box 
          sx={{
            width: '600px',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
            marginTop: 10
          }}>
          <Toolbar />
          <Typography sx={{ marginBottom: 1, fontWeight: 'bold', color: 'red', textDecoration: 'underline' }}>
            WARNING
          </Typography>
          <Typography sx={{ marginBottom: 3, color: 'red' }}>
            Access to information on this machine and network is restricted to authorised personnel only. Any unauthorised user is subject to <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>criminal prosecution</span> under the Computer Misuse and Cybersecurity Act (Cap.50A).
          </Typography>
          <Typography sx={{ marginBottom: 2 }}>
            <Button variant="contained" onClick={handleLoginClick}>Login</Button>
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

function SearchPage() {
  const [files, setFiles] = useState([]);
  const [employeeId, setEmployeeId] = useState('');
  const [folderName, setFolderName] = useState('');
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserEmail, setCurrentUserEmail] = useState('');
  const [currentEntity, setCurrentEntity] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [folderConfidentialityMapping, setFolderConfidentialityMapping] = useState([]);
  const { instance, accounts } = useMsal();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [userAccessToken, setUserAccessToken]= useState('');
  const [docLibName, setDocLibName] = useState('');

  //const onSearch = async (employeeId, currentUserEmail, folderName, confidentialityLevel, searchFoldersOnly, currentEntity, userAccessToken) => {
  const onSearch = async (employeeId, currentUserEmail, folderName, searchFoldersOnly, currentEntity, userAccessToken) => {
    setLoading(true);
    setErrorMessage('');
    setEmployeeId(employeeId); 
    setFolderName(folderName);
    const folderMappingResponse = await fetchFolderTypeMapping(userAccessToken, employeeId, currentEntity);
    setFolderConfidentialityMapping(folderMappingResponse.folderTypeMappingItems);
      if (searchFoldersOnly) {
        onClearSearchResults();
        if(folderMappingResponse.doesEmpExist){
          setFiles(Object.keys(folderMappingResponse.folderTypeMappingItems).map(folderType => ({ name: folderType, isFolder: true })));
        }else{
          setErrorMessage(`Employee: ${employeeId} does not exist!`);
        }
        setIsAdmin(false); 
      } else {
        //setFolderPath(`${confidentialityLevel}/${employeeId}/${folderName}`);
        //const response = await fetchFilesFromSharePoint(`${confidentialityLevel}/${employeeId}/${folderName}`, employeeId, folderName, userAccessToken, currentEntity);
        const docLibEnvName=`REACT_APP_PUBLIC_SEARCH_CENTRE_DOC_LIB_NAME_${currentEntity}`;
        const docLib = `${process.env[docLibEnvName]}`;
        setDocLibName(docLib);
        const response = await fetchFilesFromSharePoint(docLib, `${employeeId}/${folderName}`, employeeId, folderName, userAccessToken, currentEntity, true);
        if(response.success){
          onClearSearchResults();
          setFiles(response.files);
          if(response.files && response.files.length ===0){
            setErrorMessage("No files found in the selected folder.");
          }
          setIsAdmin(response.isCurrentUserAdmin); 
        }else{
          onClearSearchResults();
          setIsAdmin(response.isCurrentUserAdmin); 
          setErrorMessage(response.message);
        }
      }
    setLoading(false);
  };

  const onClearSearchResults = () => {
    setFiles([]);
  };

  const handleLogin = () => {
    // instance
    // .handleRedirectPromise()
    // .then((tokenResponse) => {
    //   if (!tokenResponse) {
    //     if (!instance.getActiveAccount()) {
          instance.loginRedirect(loginRequest);
    //     }
    //   }
    // });
  };  

  const handleEntityChange = async (entity) => {
    setLoading(true);
    setSelectedEntity(entity);
    setEmployeeId('');
    setFolderName('');
    setFiles([]);
    setErrorMessage('');
    setIsAdmin(false);
    const folderMappingResponse = await fetchFolderTypeMapping(userAccessToken, '', entity);
    setFolderConfidentialityMapping(folderMappingResponse.folderTypeMappingItems);
    setLoading(false);
  };
  
  useEffect(() => {
    const pageLoad = async () => {
      setLoading(true);
      try {
        const response = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });
        const userInfo = await getCurrentUserInfo(response.accessToken);
        setUserAccessToken(response.accessToken);
        setCurrentUser(userInfo);
        const userEmail = userInfo?.mail;
        setCurrentUserEmail(userEmail);
        //Role format: PSACC.Member
        const appMemberRoles = accounts[0].idTokenClaims?.roles?.filter(appRole => appRole.endsWith('.Member')) || [];
        const userEntities = appMemberRoles?.map(item => item.split('.')[0]);
        if(userEntities && userEntities != null && userEntities.length > 0){
          setCurrentEntity(userEntities);
          //Test Code
          //setCurrentEntity(prev => [...prev, "PSAMR"]);
          setSelectedEntity(userEntities[0]);
        }else{
          return;
        }
        
        const urlParams = new URLSearchParams(window.location.search);
        const empid = urlParams.get('empid');
        if (empid && empid !== '') {
          setEmployeeId(empid);
        }
        
        const folderMappingResponse = await fetchFolderTypeMapping(response.accessToken, empid || employeeId, userEntities[0]);
        if(empid && empid !== ''){
          if(folderMappingResponse.doesEmpExist){
            setFiles(Object.keys(folderMappingResponse.folderTypeMappingItems).map(folderType => ({ name: folderType, isFolder: true })));
          }else{
            setErrorMessage(`Employee: ${empid} does not exist!`);
          }
        }
        setFolderConfidentialityMapping(folderMappingResponse.folderTypeMappingItems);
    
        const expireDateTime = response.expiresOn;
        console.log("Access Token expire on: ", expireDateTime);
        const checkTime = () => {
          const currentTime = new Date();
          if (currentTime >= expireDateTime) {
            setIsDialogOpen(true);
          }
        };

        const interval = setInterval(checkTime, 1000);

        return () => clearInterval(interval);
      } catch (error) {
        console.error("Error page loading", error);
      } finally {
        setLoading(false);
      }
    };
  
    pageLoad();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts, instance]);
  
  if((!currentEntity || currentEntity === null) && !loading){
    return (
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="error">Entity cannot be retrieved, please contact your administrator.</Alert>
      </Stack>
    );
  }else{
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth="lg">
          <Typography variant="h6" gutterBottom>
          Welcome {(currentUser && `${currentUser.displayName}`) || <CircularProgress size={20} disableShrink />}
          </Typography>
          <br/><br/>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box mb={2}>
                  <FormControl sx={{ width: 250, height: 58 }}>
                      <InputLabel id="entity-label">Entity</InputLabel>
                      <Select 
                          labelId="entity-label"
                          label="Entity"
                          value={selectedEntity} 
                          onChange={(e) => handleEntityChange(e.target.value)}
                          disabled={currentEntity?.length === 1}
                      >
                          {currentEntity?.map((entity) => (
                              <MenuItem key={entity} value={entity}>{entity}</MenuItem>
                          ))}
                      </Select>
                  </FormControl>
              </Box>
              <SearchPanel onSearch={onSearch} currentUserEmail={currentUserEmail} empId={employeeId} folderConfidentialityMapping={folderConfidentialityMapping} currentEntity={selectedEntity} userAccessToken={userAccessToken} folderName={folderName}/>
            </Grid>
            <Grid item xs={12}>
              <SearchResult files={files} onSearch={onSearch} employeeId={employeeId} folderName={folderName} isAdmin={isAdmin} setLoading={setLoading} folderConfidentialityMapping={folderConfidentialityMapping} currentUserEmail={currentUserEmail} currentUserEntity={selectedEntity} userAccessToken={userAccessToken} docLibName={docLibName}/>
            </Grid>
            <Grid item xs={12}>
              {errorMessage && <Box style={{ color: 'red' }}>{errorMessage}</Box>}
            </Grid>
            </Grid>
        </Container>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Dialog open={isDialogOpen} disableBackdropClick disableEscapeKeyDown>
          <DialogTitle>Session Expired</DialogTitle>
          <DialogContent>Your session has expired. Please log in again.</DialogContent>
          <DialogActions>
            <Button onClick={handleLogin} color="primary">
              Log In Again
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
  }
}

function UploadCentrePage() {
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserEmail, setCurrentUserEmail] = useState('');
  const [currentEntity, setCurrentEntity] = useState(null);
  const [selectedEntity, setSelectedEntity] = useState('');
  const { instance, accounts } = useMsal();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [userAccessToken, setUserAccessToken]= useState('');
  const [files, setFiles] = useState([]);
  const [docLibName, setDocLibName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = () => {
    instance.loginRedirect(loginRequest);
  };

  const onClearSearchResults = () => {
    setFiles([]);
  };

  async function onSearch(docLibName, userEntity) {
    setLoading(true);
    const fileResponse = await fetchFilesFromSharePoint(docLibName, '', '', '', userAccessToken, userEntity, false);
    if (fileResponse.success) {
      onClearSearchResults();
      setFiles(fileResponse.files);
      if (fileResponse.files && fileResponse.files.length === 0) {
        setErrorMessage("No files found in the document library.");
      }
    } else {
      onClearSearchResults();
      setErrorMessage(fileResponse.message);
    }
    setLoading(false);
  }

  useEffect(() => {
    const pageLoad = async () => {
      setLoading(true);
      setErrorMessage('');
      try {
        const response = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });
        const userInfo = await getCurrentUserInfo(response.accessToken);
        setUserAccessToken(response.accessToken);
        setCurrentUser(userInfo);
        const userEmail = userInfo?.mail;
        setCurrentUserEmail(userEmail);
        //Role format: PSACC.Member
        const appMemberRoles = accounts[0].idTokenClaims?.roles?.filter(appRole => appRole.endsWith('.Member')) || [];
        const userEntities = appMemberRoles?.map(item => item.split('.')[0]);
        if(userEntities && userEntities != null && userEntities.length > 0){
          setCurrentEntity(userEntities);
          setSelectedEntity(userEntities[0]);
        }else{
          return;
        }
    
        const expireDateTime = response.expiresOn;
        console.log("Access Token expire on: ", expireDateTime);
        
        const docLibEnvName=`REACT_APP_PUBLIC_UPLOAD_CENTRE_DOC_LIB_NAME_${userEntities[0]}`;
        const docLib = `${process.env[docLibEnvName]}`;
        setDocLibName(docLib);
        await onSearch(docLib, userEntities[0]);
        //Check if session has expired
        const checkTime = () => {
          const currentTime = new Date();
          if (currentTime >= expireDateTime) {
            setIsDialogOpen(true);
          }
        };

        const interval = setInterval(checkTime, 1000);

        return () => clearInterval(interval);
      } catch (error) {
        console.error("Error page loading", error);
      } finally {
        setLoading(false);
      }
    };
  
    pageLoad();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts, instance]);

  if((!currentEntity || currentEntity === null) && !loading){
    return (
      <Stack sx={{ width: '90%' }} spacing={2}>
        <Alert severity="error">Entity cannot be retrieved, please contact your administrator.</Alert>
      </Stack>
    );
  }else{
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container style={{ paddingLeft: 0, }}>
          <Typography variant="h6" gutterBottom>
          Welcome {(currentUser && `${currentUser.displayName}`) || <CircularProgress size={20} disableShrink />}
          </Typography>
        </Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box mb={2}>
              <UploadCentre onSearch={onSearch} files ={files} docLibName={docLibName} setLoading={setLoading} userAccessToken={userAccessToken} currentEntity={selectedEntity} currentUserEmail={currentUserEmail} roleEntities={currentEntity} />
            </Box>
          </Grid>
        </Grid>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Dialog open={isDialogOpen} disableBackdropClick disableEscapeKeyDown>
          <DialogTitle>Session Expired</DialogTitle>
          <DialogContent>Your session has expired. Please log in again.</DialogContent>
          <DialogActions>
            <Button onClick={handleLogin} color="primary">
              Log In Again
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
  }
}

function ListsPage(){
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentEntity, setCurrentEntity] = useState(null);
  const [selectedEntity, setSelectedEntity] = useState('');
  const { instance, accounts } = useMsal();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [userAccessToken, setUserAccessToken]= useState('');
  const [columns, setColumns] = useState([]);
  const [listitems, setListitems] = useState(null);
  const [selectedList, setListEntity] = useState(null);
  const [spoListNameDic, setSpoListNameDic] = useState(null);

  const handleLogin = () => {
    instance.loginRedirect(loginRequest);
  };

  async function getListItems(token, listId, entity) {
    setLoading(true);
    var result = await fetchListItems(token, listId, entity);
    if (result && result.length > 0) {
        const firstItem = result[0];
        let dynamicColumns = Object.keys(firstItem)
        .filter(key => !key.endsWith('_displayName'))
        .map((key) => {
            let columnConfig = {
                field: key,
                headerName: firstItem[`${key}_displayName`] || key,
                flex: 1,
                editable: false
            };

            if (key === 'Created' || key === 'Modified') {
                columnConfig = {
                    ...columnConfig,
                    renderCell: (params) => {
                        if (!params.value) return '';
                        try {
                            const date = new Date(params.value);
                            return date.toLocaleString('zh-CN', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                                hour12: false
                            });
                        } catch (error) {
                            console.error('Format datetime error:', error);
                            return params.value;
                        }
                    }
                };
            }

            return columnConfig;
        });
        //console.log(`dynamicColumns: ${JSON.stringify(dynamicColumns)}`)
        //Move LinkTitle to the 1st.
        const linkTitleColumn = dynamicColumns.find(col => col.field === 'LinkTitle');
        if (linkTitleColumn) {
          dynamicColumns = dynamicColumns.filter(col => col.field !== 'LinkTitle');
          
          dynamicColumns.unshift(linkTitleColumn);
        }
        setColumns(dynamicColumns);
        setListitems(result);
    }
    setLoading(false);
  };

  useEffect(() => {
    const pageLoad = async () => {
      setLoading(true);
      try {
        const response = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });
        const userInfo = await getCurrentUserInfo(response.accessToken);
        setUserAccessToken(response.accessToken);
        setCurrentUser(userInfo);
        //Role format: PSACC.Member
        const appMemberRoles = accounts[0].idTokenClaims?.roles?.filter(appRole => appRole.endsWith('.Member')) || [];
        const userEntities = appMemberRoles?.map(item => item.split('.')[0]);
        if(userEntities && userEntities != null && userEntities.length > 0){
          setCurrentEntity(userEntities);
          setSelectedEntity(userEntities[0]);
        }else{
          return;
        }

        let dictionaryArray = [];
        try {
            const dictionaryObj = JSON.parse(process.env.REACT_APP_PUBLIC_SPO_LIST_NAME_DIC);
            dictionaryArray = Array.isArray(dictionaryObj) ? dictionaryObj : Object.entries(dictionaryObj).map(([key, value]) => ({key, value}));
        } catch (error) {
            console.error('Error parsing REACT_APP_DICTIONARY:', error);
        }
        setSpoListNameDic(dictionaryArray);
        setListEntity(dictionaryArray[0].value);

        const listIdEnvName = `REACT_APP_PUBLIC_${dictionaryArray[0].value}_ID_${userEntities[0]}`;
        const listId = process.env[listIdEnvName];
        await getListItems(response.accessToken, listId, userEntities[0]);

        const expireDateTime = response.expiresOn;
        console.log("Access Token expire on: ", expireDateTime);
        
        //Check if session has expired
        const checkTime = () => {
          const currentTime = new Date();
          if (currentTime >= expireDateTime) {
            setIsDialogOpen(true);
          }
        };

        const interval = setInterval(checkTime, 1000);

        return () => clearInterval(interval);
      } catch (error) {
        console.error("Error page loading", error);
      } finally {
        setLoading(false);
      }
    };
  
    pageLoad();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts, instance]);

  if((!currentEntity || currentEntity === null) && !loading){
    return (
      <Stack sx={{ width: '90%' }} spacing={2}>
        <Alert severity="error">Entity cannot be retrieved, please contact your administrator.</Alert>
      </Stack>
    );
  }else{
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container style={{ paddingLeft: 0, }}>
          <Typography variant="h6" gutterBottom>
          Welcome {(currentUser && `${currentUser.displayName}`) || <CircularProgress size={20} disableShrink />}
          </Typography>
        </Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box mb={2}>
              <Lists userAccessToken={userAccessToken} currentEntity={selectedEntity} roleEntities={currentEntity} spoListNameDic={spoListNameDic} listitems={listitems} columns={columns} getListItems={getListItems} currentListId={selectedList} />
            </Box>
          </Grid>
        </Grid>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Dialog open={isDialogOpen} disableBackdropClick disableEscapeKeyDown>
          <DialogTitle>Session Expired</DialogTitle>
          <DialogContent>Your session has expired. Please log in again.</DialogContent>
          <DialogActions>
            <Button onClick={handleLogin} color="primary">
              Log In Again
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
  }
}

function MainPage() {
  const location = useLocation();

  const CustomListItemButton = styled(ListItemButton)(({ theme }) => ({
    '&.Mui-selected': {
      backgroundColor: '#f5f5f5', 
      '&:hover': {
        backgroundColor: '#f5f5f5', 
      },
    },
  }));
  return (
      <Box sx={{ display: 'flex', }}>
        <CssBaseline />
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: '#000000' }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h5" gutterBottom noWrap component="div">
                (Electronic) Personnel Records Filing System
              </Typography>
              <Typography variant="h6" noWrap component="div">
                <img src="/PSALogo.png" alt="PSA Logo" className="psa-logo" />
              </Typography>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: 'auto' }}>
            <List>
              <CustomListItemButton selected={location.pathname === '/'}
                                    component={Link} to="/">
                <ListItemIcon>
                  <SearchIcon />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </CustomListItemButton>
              <CustomListItemButton selected={location.pathname === '/uploadcentre'}
                                    component={Link} to="/uploadcentre">
                <ListItemIcon>
                  <UploadIcon />
                </ListItemIcon>
                <ListItemText primary="Upload Centre" />
              </CustomListItemButton>
              <CustomListItemButton selected={location.pathname === '/lists'}
                                    component={Link} to="/lists">
                <ListItemIcon>
                  <ListIcon />
                </ListItemIcon>
                <ListItemText primary="Lists" />
              </CustomListItemButton>
            </List>
          </Box>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, paddingLeft: 3, paddingRight:3, paddingTop:2, }}>
          <Toolbar />
          <Typography sx={{ marginBottom: 2 }}>
            <Routes>
              <Route path="/" element={<SearchPage />} />
              <Route path="/uploadcentre" element={<UploadCentrePage />} />
              <Route path="/lists" element={<ListsPage />} />
            </Routes>
          </Typography>
        </Box>
      </Box>
  );
}

function App() {
  return (
    <>
      <AuthenticatedTemplate>
        <Router>
          <MainPage />
        </Router>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginPage />
      </UnauthenticatedTemplate>
    </>
  );
}

export default App;